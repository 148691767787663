var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AcquisitionStyled', [_c('AcquisitionList', {
    attrs: {
      "acquisition": _vm.created
    }
  }), _c('router-view', {
    on: {
      "reload": function reload($event) {
        _vm.created = $event;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }