<template>
  <AcquisitionStyled>
    <AcquisitionList :acquisition="created" />
    <router-view @reload="created = $event" />
  </AcquisitionStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import AcquisitionList from './Acquisition/AcquisitionList.vue'

const AcquisitionStyled = styled('div')`
  margin: 1rem;
  .actions {
    display: flex;
    flex-direction: row;
    padding: 5px;
    justify-content: space-around;

    color: ${p => p.theme.colors.primary};

    > div {
      cursor: pointer;
      &:hover {
        color: ${props => props.theme.colors.white};
      }
    }
  }
`

export default {
  components: {
    AcquisitionStyled,
    AcquisitionList,
  },
  data() {
    return {
      created: null,
    }
  },
}
</script>
