<template>
  <AcquisitionsStyled>
    <PanelTableMolecule
      :isLoading="loading"
      :count="totalCount"
      :title="$tc('acquisition', 2) | capitalize"
      :columns="columns"
      :rows="tableData"
      :pageIndex="page + 1"
      :pageSize="perPage"
      :createRouteName="createRoute"
      @pageNumberChange="pageNumberChange($event)"
      @reload="reload"
    />
  </AcquisitionsStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import gql from 'graphql-tag'
import { getRolesFromToken } from '@common/utils'
import { Edit2Icon, SearchIcon, TrashIcon } from 'vue-feather-icons'

import ACQUISITIONS_QUERY from '#/graphql/acquisition/listAcquisitions.gql'

import PanelTableMolecule from '@/components/Atomic/Molecules/PanelTableMolecule'
import TableColumnSearch from '@/components/Atomic/Molecules/TableColumnSearch'
import EntityLinkAtom from '@/components/Atomic/Atoms/EntityLinkAtom'

const AcquisitionsStyled = styled('div')`
  margin: 1rem;
  .actions {
    display: flex;
    flex-direction: row;
    padding: 5px;
    justify-content: space-around;

    color: ${p => p.theme.colors.primary};

    > div {
      cursor: pointer;
      &:hover {
        color: ${props => props.theme.colors.white};
      }
    }
  }
`

export default {
  components: {
    AcquisitionsStyled,
    PanelTableMolecule,
  },
  props: {
    acquisition: {
      type: String,
    },
  },
  data() {
    return {
      loading: true,
      count: 0,
      totalCount: 0,
      models: [],
      errors: [],
      searchQuery: '',
      where: null,
      perPage: 10,
      page: 0,
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalCount / this.perPage)
    },
    hasNextPage() {
      return this.page < this.totalPages - 1
    },
    hasPreviousPage() {
      return this.page > 0
    },
    skip() {
      return this.page * this.perPage
    },
    userRoles() {
      return getRolesFromToken(this.$keycloak.token)
    },
    hasWritePermission() {
      return this.userRoles.includes('archon') || this.userRoles.includes('talpa-product')
    },
    editRoute() {
      if (this.hasWritePermission) {
        return 'acquisition'
      } else {
        return null
      }
    },
    createRoute() {
      if (this.hasWritePermission) {
        return 'createAcquisition'
      } else {
        return null
      }
    },
    columns() {
      const header = [
        {
          field: 'organizationName',
          key: 'org',
          title: this.$tc('organization', 1),
          align: 'left',
          filterCustom: {
            defaultVisible: false,
            render: ({ closeFn }) => {
              return (
                <TableColumnSearch
                  searchValue={this.searchQuery}
                  placeholder="Search name"
                  on-valueChange={e => (this.searchQuery = e)}
                  on-cancel={() => this.searchCancel(closeFn)}
                  on-confirm={() => this.searchConfirm(closeFn)}
                />
              )
            },
            // custom filter icon
            filterIcon: () => {
              return <SearchIcon size="1.3x" />
            },
          },
          renderBodyCell: ({ row }) => {
            const { organizationId, organizationName } = row
            if (!organizationId || !organizationName) {
              return `something missing`
            }
            return <router-link to={{ name: 'OrganizationAcquisitions', params: { id: organizationId } }}>{organizationName}</router-link>
          },
        },
        {
          field: 'productName',
          key: 'pro',
          title: this.$tc('product', 1),
          align: 'left',
          filterCustom: {
            defaultVisible: false,
            render: ({ closeFn }) => {
              return (
                <TableColumnSearch
                  searchValue={this.searchQuery}
                  placeholder="Search name"
                  on-valueChange={e => (this.searchQuery = e)}
                  on-cancel={() => this.searchCancel(closeFn)}
                  on-confirm={() => this.searchConfirm(closeFn)}
                />
              )
            },
            // custom filter icon
            filterIcon: () => {
              return <SearchIcon size="1.3x" />
            },
          },
          renderBodyCell: ({ row }) => {
            const { productId, productName } = row
            if (!productId || !productName) {
              return `something missing`
            }
            return <EntityLinkAtom type={'Product'} id={productId} label={productName} />
          },
        },
        {
          field: 'tierLevel',
          key: 'tier',
          title: 'Tier Level',
          align: 'left',
        },
        {
          field: 'status',
          key: 'status',
          title: 'Status',
          align: 'left',
        },
        {
          field: 'date',
          key: 'date',
          title: 'Date',
          align: 'left',
        },
        {
          field: 'actions',
          key: 'i',
          title: 'Actions',
          width: '',
          center: 'left',
          renderBodyCell: ({ row }) => {
            return (
              <div class="actions">
                <div on-click={() => this.confirmDelete(row)}>
                  <TrashIcon size="1.3x" />
                </div>
                <div on-click={() => this.$router.push({ name: this.editRoute, params: { id: row?.rowKey } })}>
                  <Edit2Icon size="1.3x" />
                </div>
              </div>
            )
          },
        },
      ]
      return header
    },
    tableData() {
      const tableData = this.models.map(acq => {
        return {
          ...acq,
          rowKey: acq.id,
          organizationName: acq.organization?.name,
          productId: acq.productTierLevel?.product?.id,
          productName: acq.productTierLevel?.product?.name,
          tierLevel: `Tier ${acq?.productTierLevel?.tierLevel}`,
          date:
            acq?.status === 'ORDERED'
              ? acq?.orderedAt
              : acq?.status === 'TRIAL_STARTED'
              ? acq?.trialStartedAt
              : acq?.status === 'PURCHASED'
              ? acq?.purchasedAt
              : acq?.updatedAt,
        }
      })
      return tableData
    },
  },
  methods: {
    reload() {
      this.searchQuery = ''
      this.$apollo.queries.models.refetch()
    },
    pageNumberChange(pageIndex) {
      this.page = pageIndex - 1
    },
    searchCancel(closeFn) {
      this.searchQuery = ''
      closeFn()
    },
    searchConfirm(closeFn) {
      closeFn()
    },
    confirmDelete(model) {
      this.$root.$emit(
        'activateOverlay',
        'ConfirmDeleteOverlay',
        {
          type: 'Acquisition',
          instance: model,
          labelKey: 'name',
          onConfirm: this.delete,
          onConfirmArgs: model,
        },
        this.onCloseSettings,
      )
    },
    async delete(model) {
      const res = await this.$apollo.mutate({
        mutation: gql`
          mutation ($where: AcquisitionWhereUniqueInput!) {
            deleteOneAcquisition(where: $where) {
              id
            }
          }
        `,
        variables: {
          where: {
            id: model.id,
          },
        },
      })
      if (res) {
        this.$root.$emit('closeOverlay')
        this.reload()
      }
    },
  },
  watch: {
    acquisition: {
      handler() {
        this.reload()
      },
      immediate: false,
    },
    searchQuery: {
      handler(s) {
        this.page = 0
        if (s) {
          this.where = {
            OR: [
              {
                name_contains: s,
              },
              {
                alias_contains: s,
              },
            ],
          }
        } else {
          this.where = undefined
        }
      },
    },
  },
  apollo: {
    models: {
      query: ACQUISITIONS_QUERY,
      variables() {
        return {
          take: this.perPage,
          skip: this.skip,
          where: this.where ? this.where : undefined,
        }
      },
      manual: true,
      result({ data, loading }) {
        this.loading = loading
        this.models = data?.acquisitions
        this.totalCount = data?.aggregateAcquisition?._count?.id
        this.count = data?.aggregateAcquisition?._count?._all || 0
        this.totalCount = data?.aggregateAcquisition?._count?._all || 0
      },
    },
  },
}
</script>
